<template>
  <div class="container">
    <page-title :title="`Add Company`" />

    <div class="row justify-content-cente">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="save">
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Name</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.name"
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Registration Code</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.crp"
                        placeholder="e.g CPR/2001/2650"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Email</label>
                      <input
                        required
                        type="email"
                        class="form-control"
                        v-model="company.email"
                        placeholder="Company Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Phone Number</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.phone_number"
                        placeholder="Company Phone Number"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Physical Address</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.physical_address"
                        placeholder="Physical Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Company Postal Address</label>
                      <input
                        required
                        type="text"
                        class="form-control"
                        v-model="company.postal_address"
                        placeholder="Postal Address"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <button class="btn btn-secondary float-right">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      company: {
        name: "",
      },
      company_resolution: "",
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get(`/api/v1/dashboard/companies/${this.$route.params.id}`).then(response => {
        this.company = response.data
        this.$loader.stop()
      })
    },

    save() {
      this.$loader.start()

      let data = {
        name: this.company.name,
        crp: this.company.crp,
        email: this.company.email,
        phone_number: this.company.phone_number,
        physical_address: this.company.physical_address,
        postal_address: this.company.postal_address
      }

      this.$axios
        .put(`/api/v1/dashboard/companies/${this.company.id}`, data)
        .then(() => {
          this.$loader.stop()
          this.$router.push({ name: "companies.show" });
        })
        .catch((error) => {
          this.$loader.stop()
          this.$alert.httpError(error.response);
        });
    },
  },
};
</script>
